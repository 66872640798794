//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import componentMinxins from '../ComponentMinxins';
//import { upLoadFileApi, downLoadFileApi } from '@/api/design';
//import {  downloadFileBlob } from '@/utils/index';

export default {
  mixins: [componentMinxins],
  name: 'ImageUpload',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: '请选择图片',
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    maxNumber: {
      type: Number,
      default: 10,
    },
    enableZip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sizeTip() {
      return this.maxSize > 0 ? `| 每张图不超过${this.maxSize}MB` : '';
    },
  },
  data() {
    return {
      disabled: false,
      uploadParams: {},
    };
  },
  methods: {
       // 覆盖默认的上传行为
       requestUpload() {

       },
    beforeUpload(file) {
      const alows = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
      if (alows.indexOf(file.type) === -1) {
        this.$message.warning('存在不支持的图片格式');
      } else if (this.maxSize > 0 && file.size / 1024 / 1024 > this.maxSize) {
        this.$message.warning(`单张图片最大不超过 ${this.maxSize}MB`);
      } else {
         //上传文件的需要formdata类型;所以要转
        let FormDatas = new FormData();
        FormDatas.append('file', file);
        upLoadFileApi(FormDatas).then(res => {
        console.log('uploadFile', res);

        if (res.data.result) {
            
            this._value.push(res.data.result); //成功过后手动将文件添加到展示列表里
            console.log("   {{_value}}",this._value)
            this.$emit('input', this._value);
          }
        });
        return true;
      }
      return false;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
    handleDownload(file) {
        //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData();
      FormDatas.append('name', file.name);
      downLoadFileApi(FormDatas).then(res => {
        if (res.data) {
          downloadFileBlob(res.data,file.name)
        }
      });
    },
  },
};
